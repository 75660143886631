import React from "react";
import "./ShareImage.css";
import trophy1 from "../../assets/trophy1.svg";

function ShareImage({yourJourneyData,...props}) {
    return (yourJourneyData && yourJourneyData.shareMetadata ?
        <div className="ShareImage" id="share-image">
            <div  className="ShareImage-content">
                <p className="ShareImage-heading">{yourJourneyData.shareMetadata.title}</p>
                <p className="ShareImage-subheading">{yourJourneyData.shareMetadata.subtitle}</p>
                <img src={yourJourneyData.shareMetadata.icon ? yourJourneyData.shareMetadata.icon:trophy1} alt="" className="ShareImage-img"/>
            </div>
    </div>:""
    );
}

export default ShareImage;
