export function newCleverTap(ACTION = "", properties) {
    // console.log('in newclevertap', properties);
    let userData = JSON.parse(localStorage.getItem('userInfo'))
    // if (!ACTION || (process.env.NODE_ENV != "production") || !userData) {
    //     return;
    // }

    console.log(
        "growth_Academy", {
                ...properties,
                "ACTION":ACTION,
        "USER ID": userData.userId,
            "ORG ID": userData.orgId,
        "ORG CODE": userData.orgCode,
        "USER TYPE": userData.userType,
        "PREMIUM STATUS": userData.premiumStatus,
        "User created date": userData.userCreatedAtDate,
        "Signed up count": userData.signedUpCount,
    }, "cleverTap"
    )
    return (
        //  process.env.NODE_ENV === "production" &&
        window.clevertap.event.push(
            "growth_Academy",
            JSON.parse(
                // JSON.stringify({ "Existing_journey_of_the_user": "testing" })
                JSON.stringify({
                    ...properties,
                    "ACTION":ACTION,
                    "USER ID": userData.userId,
                    "ORG ID": userData.orgId,
                    "ORG CODE": userData.orgCode,
                    "USER TYPE": userData.userType,
                    "PREMIUM STATUS": userData.premiumStatus,
                    "User created date": userData.userCreatedAtDate,
                    "Signed up count": userData.signedUpCount,
                })
            )
        )
    );
}


export function initUserProfileCleverTap(data) {
    window.clevertap.onUserLogin.push({
        Site: {
            Name: data.userName,
            Email: data.userEmail,
            Identity: data.userId, //id in token
            Phone: `+${data.userMobileNumber}`,
            "TUTOR ID": data.tutorId || ""
        }
    });
}