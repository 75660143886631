let BASE_URL = "https://api.classplusapp.com/v3";
// let prod_WebBaseUrl=`https://clp-growthstackhome.surge.sh`
let prod_WebBaseUrl = `${window.location.origin}`;
if (process.env.REACT_APP_BUILD_ENV === "development") {
  BASE_URL = "https://api.classplusapp.com/v3";
} else {
  if (process.env.REACT_APP_BUILD_ENV === "staging") {
    BASE_URL = "https://api.classplusapp.com/v3";
  } else if (process.env.REACT_APP_BUILD_ENV === "preprod") {
    BASE_URL = "https://api.classplusapp.com/v3";
  } else if (process.env.REACT_APP_BUILD_ENV === "production") {
    BASE_URL = "https://api.classplusapp.com/v3";
  }
}

export default {
  BASE_URL,
  prod_WebBaseUrl,
};
