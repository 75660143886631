import i18n from  "../../i18n";

const { t } = i18n;

export const data=[
    {
        status:1,
        text:t("goalsModalData.100Students","100 students")
    },
    {
        status:0,
        text:t("goalsModalData.350Students","350 students")
    },
    {
        status:0,
        text:t("goalsModalData.5000Students","5,000 students")
    },
    {
        status:0,
        text:t("goalsModalData.10000Students","10,000 students")
    }
]