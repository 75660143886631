import API from "./appConfig";
import { toast } from "react-toastify";
import {defaultToken,getQuery} from "./utils";

let token = getQuery()['token'] //|| defaultToken;
let idData = null
let headers={
    "Content-Type": "application/json",
    "Api-Version":11,
    "x-access-token": token,
    // "Access-Control-Allow-Origin": process.env.REACT_APP_BUILD_ENV === 'staging'?"http://clp-growthstack-staging.surge.sh/":"http://localhost:3000/",
    // "credentials": 'include'

  };
export const tokenData = token
export const getJourneyData = (id) => {
  idData = id
    return new Promise((resolve, reject) => {
      let URL= id ? `${API.BASE_URL}/growthStack/journey/${id}` : `${API.BASE_URL}/growthStack/journey`
      fetch(URL, {
        method: "GET",
        headers: headers,
      })
        .then((response) => response.json())
        .then((response) => resolve(response.data))
        .catch((err) => {
          toast.error("Something went wrong")
          reject(err);
        });
    });
  };

  export const getAllJourneyData = () => {
    return new Promise((resolve, reject) => {
      fetch(`${API.BASE_URL}/growthStack/allJourney`, {
        method: "GET",
        headers: headers,
      })
        .then((response) => response.json())
        .then((response) => resolve(response.data))
        .catch((err) => {
          toast.error("Something went wrong")
          reject(err);
        });
    });
  };



  export const getAwardData = (id) => {
    return new Promise((resolve, reject) => {
      fetch(`${API.BASE_URL}/growthStack/getAward/?journeyId=${id}`, {
        method: "GET",
        headers: headers,
      })
        .then((response) => response.json())
        .then((response) => resolve(response.data))
        .catch((err) => {
          toast.error("Something went wrong")
          reject(err);
        });
    });
  };

export const milestoneAchievedCallback = (name) => {
  return new Promise((resolve, reject) => {
    fetch(`${API.BASE_URL}/growthStack/milestoneAchieved?milestoneAchieved=${name}`, {
      method: "PATCH",
      headers: headers,
    })
      .then((response) => response.json())
      .then((response) => {
        getJourneyData(idData);
        resolve(response.data)
      })
      .catch((err) => {
        toast.error("Something went wrong")
        reject(err);
      });
  });
};

export const milestoneChatBroadCast = () => {
  return new Promise((resolve, reject) => {
    fetch(`${API.BASE_URL}/growthStack/broadcast?message=CHAT_BROADCAST`, {
      method: "POST",
      headers: headers,
    })
      .then((response) => response.json())
      .then((response) => {
        getJourneyData(idData);
        resolve(response.data)
      })
      .catch((err) => {
        toast.error("Something went wrong")
        reject(err);
      });
  });
};

  export const milestoneAchievedCall = (id) => {
    return new Promise((resolve, reject) => {
      fetch(`${API.BASE_URL}/growthStack/milestoneAchieved?milestoneAchieved=${id}`, {
        method: "PATCH",
        headers: headers,
      })
        .then((response) => response.json())
        .then((response) => {
          getJourneyData(idData);;
          resolve(response.data)
        })
        .catch((err) => {
          toast.error("Something went wrong")
          reject(err);
        });
    });
  };

export const storyStatusCall = (id) => {
  return new Promise((resolve, reject) => {
    fetch(`${API.BASE_URL}/growthStack/updateVideo`, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ id })
    })
      .then((response) => response.json())
      .then((response) => resolve(response.data))
      .catch((err) => {
        toast.error("Something went wrong")
        reject(err);
      });
  });
};


export const updateJourneyCall = (journeyId) => {
  return new Promise((resolve, reject) => {
    fetch(`${API.BASE_URL}/growthStack/updateJourney/' `, {
      method: "POST",
      headers: headers,
      body: JSON.stringify({ journeyId })
    })
      .then((response) => response.json())
      .then((response) => resolve(response.data))
      .catch((err) => {
        toast.error("Something went wrong")
        reject(err);
      });
  });
};