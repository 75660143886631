
import React,{Suspense,lazy} from 'react';
import './GoalsModal.css';
import tickGreen from "../../assets/tickGreen.svg";
import tickGrey from "../../assets/tickGrey.svg";
import {data} from "./goalsModalData"
import { handleDeeplink } from '../../utils';
import { Trans , useTranslation } from "react-i18next";
const Modal =lazy(()=>import('../../UI/Modal/Modal'))
const FormatModal=lazy(()=>import('../../UI/FormatModal/FormatModal'))
const Button=lazy(()=>import('../../UI/Button/Button'))



const GoalsModal = ({setGoalsModal,goalsModal,goalsModalData,...props}) => {
    const { t } = useTranslation();
	return (<Suspense fallback={"Loading..."}>
    <Modal toggle={()=>setGoalsModal(false)} hidden={!goalsModal}>
				{goalsModalData ?<FormatModal title={goalsModalData.milestones && goalsModalData.milestones.title}>
                    <div className="goalsModal">
                        <p className="goalsModal-note" style={{background:goalsModalData.bgColor}}>
                            {goalsModalData.milestones.subtitle}
                        </p>
                        <div className="goalsModal-progressContainer">
                        {goalsModalData.milestones.milestoneArray.map((dt,ind)=>{return(
                          
                            <div className="goalsModal-progressRow">
                                <div className="goalsModal-progressRow-col1">
                                        <img src={dt.status==1 ? tickGreen:tickGrey} alt="" className="goalsModal-progressRow-col1-img"/>
                                        {data.length != ind  ? <div className="goalsModal-progressRow-col1-line" style={dt.status==1 ?{}:{background:"#DDDDDD"}}></div>:""}
                                </div>
                                <p className="goalsModal-progressRow-col2" style={dt.status==2 ? {color:"#CA9306"}: dt.status == 1 ? {color:"#3E9C4C"}:{color:"#000000"}}>
                                   {dt.title}
                                </p>
                                <p className="goalsModal-progressRow-col3" style={dt.status==2 ? {color:"#CA9306"}: dt.status == 1 ? {color:"#3E9C4C"}:{}}> {dt.status==2  ? goalsModalData.percentage : dt.status ==1 ? t("goalsModal.completed","Completed"):""}</p>
                            </div>   
                       
                        )})}
                         </div>
                        <div className="goalsModal-footer">
                            <Button primary text={goalsModalData.milestones.cta.title} width={"100%"} 
                                    onClick={()=>{handleDeeplink(goalsModalData.milestones.cta.deeplink.screen,goalsModalData.milestones.cta.deeplink.paramOne)}}
                                    />
                        </div>
                    </div>
				</FormatModal>:""}
			</Modal>
            </Suspense>
	);
};

export default GoalsModal;

