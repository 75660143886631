
import React,{Suspense,lazy,useEffect} from 'react';
import './CongratsModal.css';
import trophy from "../../assets/trophy.svg";
import coins from "../../assets/coins.svg";
import share from "../../assets/share.svg";
import {getJourneyData} from "../../api"
import {newCleverTap} from "../../cleverTap";
import { withRouter } from 'react-router-dom';
import { newWebEngage } from '../../webEngage';



const Modal =lazy(()=>import('../../UI/Modal/Modal'))
const FormatModal=lazy(()=>import('../../UI/FormatModal/FormatModal'))
const Button=lazy(()=>import('../../UI/Button/Button'))

const CongratsModal = ({handleShareImgBase,setCongratsModal,setIsShared,setYourJourneyData,congratsModal,congratsData,isShared,yourJourneyData,...props}) => {
    // const parent1 = document.createElement("div");
    // let unlisten
    // document.body.append(parent1);
    // useEffect(() => {
    //     unlisten = props.history.listen((location, action) => {
    //       props.setCongratsModal(null)
    //     });
    //     return () => {
    //       unlisten();
    //       parent1.remove();
    //     };
    //   }, []);
    
    const handleCTAClick =()=>{
        if(isShared || (congratsData && congratsData.journeyId == 4)){
            setIsShared(false)
        }else{
            congratsData &&
            getJourneyData(congratsData.journeyId+1).then((data)=>{
                setYourJourneyData(data)
            })
        }
        setCongratsModal(false)
    }
	return (<Suspense fallback={"Loading..."}>
    <Modal toggle={()=>setCongratsModal(false)} hidden={!congratsModal}>
				<FormatModal>
                    {congratsData ? <div className="congrats">
                        <img src={congratsData.icon} alt="" className="congrats-img"/>
                        <p className="congrats-content-h1">
                           {congratsData.title}
                        </p>
                        <p className="congrats-content-h2">{congratsData.subtitle}</p>
                        <div className="congrats-content-notes">
                            <img src={congratsData.reward && congratsData.reward.icon} alt="" style={{background:congratsData.reward && congratsData.reward.color}} className="congrats-content-img"/>
                                {congratsData.reward && congratsData.reward.text}
                        </div>
                        <Button onClick={()=>handleShareImgBase()} text={congratsData.cta1 && congratsData.cta1.title} secondary icon={congratsData.cta1 && congratsData.cta1.icon} width={"90%"} margin={"16px 0"}/>
                        <div className="congrats-footer">
                            <Button text={isShared || (congratsData && congratsData.journeyId == 4) ? "Continue learning":"Go to next journey"} width={"100%"} onClick={()=>{
                                isShared&&newCleverTap("Go_to next_journey",
                                {"Current Journey":yourJourneyData.journeys.name});
                                isShared&&newWebEngage('GA_Go to next journey',{"Current Journey":yourJourneyData.journeys.name})
                                handleCTAClick()}}/>
                        </div>
                    </div>:""}
				</FormatModal>
			</Modal>
            </Suspense>
	);
};

// export default withRouter(CongratsModal);
export default CongratsModal;

