
import React,{Suspense,lazy} from 'react';
import help from "../../assets/helpImg.svg";
import whatsapp from "../../assets/whatsapp.svg"
import './HelpModal.css';
import { handleDeeplink,getQuery,defaultToken, handleDeeplinkExecutedV2 } from '../../utils';
const Modal =lazy(()=>import('../../UI/Modal/Modal'))
const FormatModal=lazy(()=>import('../../UI/FormatModal/FormatModal'))
const Button=lazy(()=>import('../../UI/Button/Button'))

const HelpModal = ({yourJourneyData,setHelpModal,helpModal,...props}) => {
    let iconSize={
        width:"18px"
    }
	return (<Suspense fallback={"Loading..."}>
    <Modal toggle={()=>setHelpModal(false)} hidden={!helpModal}>
				{yourJourneyData.helpBottomSheet ? <FormatModal title={yourJourneyData.helpBottomSheet.title}>
                    <div className="helpModal">
                        <div className="helpModal-content">
                        {yourJourneyData.helpBottomSheet.subtitle}
                        <img src={help} alt=""/>
                        </div>
                        <div className="helpModal-footer">
                        <a
							href={`tel:${yourJourneyData.helpBottomSheet.cta1.deeplink.paramOne}`}
							target="_blank"
							style={{ textDecoration: 'none', cursor: 'pointer', display: 'flex', alignItems: 'center',width:"48%" }}
						>
                            <Button icon={yourJourneyData.helpBottomSheet.cta1.icon} 
                                    iconSize={iconSize}
                                    text={yourJourneyData.helpBottomSheet.cta1.title} 
                                    primary 
                                    width={"100%"} 
                                    margin={"12px 0"}/>
                        </a>
                            <button className="helpModal-whatsapp" 
                                    onClick={()=>{
                                        handleDeeplinkExecutedV2(JSON.stringify({
                                            screen: yourJourneyData.helpBottomSheet.cta2.deeplink.screen,
                                            paramOne:yourJourneyData.helpBottomSheet.cta2.deeplink.paramTwo,
                                            paramTwo:yourJourneyData.helpBottomSheet.cta2.deeplink.paramOne
                                          }),window.location.pathname == "/homeCard" ? "HOME" : "")
                                        // window.open(`https://api.whatsapp.com/send?phone=${yourJourneyData.helpBottomSheet.cta2.deeplink.paramOne}&text=${yourJourneyData.helpBottomSheet.cta2.deeplink.paramTwo}`)
                                        }}>
                                <img src={yourJourneyData.helpBottomSheet.cta2.icon} alt=""/>
                                {yourJourneyData.helpBottomSheet.cta2.title}
                            </button>
                        </div>
                    </div>
				</FormatModal>:""}
			</Modal>
            </Suspense>
	);
};

export default HelpModal;

