

export function newWebEngage(ACTION = "", properties) {
  let userData = JSON.parse(localStorage.getItem("userInfo"));
  // if (!ACTION || (process.env.NODE_ENV != "production") || !userData) {
  //     return;
  // }  

  let chromeDetails = navigator?.userAgent?.match(/Chrom(e|ium)\/([0-9]+)\./) || "";
  console.log("userdataxxx",userData)

  let last_action;

    
  last_action = window.last_action;

  window.last_action = ACTION;

  console.log(
    "growth_Academy",
    {
      ...properties,
      ACTION: ACTION,
      "cp_user_id": Number(userData.userId),
      "tutor_id" : Number(userData.tutorId),
      "org_id": Number(userData.orgId),
      "org_name" : String(userData.orgName),
      "org_code": String(userData.orgCode),
      "user_type": Number(userData.userType),
      "premium_status": Boolean(userData.premiumStatus),
      "user_created_at": new Date(userData.userCreatedAtDate),
      "signed_up_count": Number(userData.signedUpCount),
      "screen_name" : String(properties.screen_name),
      "email" : String(userData.userEmail),
      "mobile" : Number(userData.userMobileNumber),
      'user_name' : String(userData.userName),
      last_action : last_action || null,
      'cp_sdk_source' : "webview",
      is_user_id_even:getEvenUserId(userData.userId),
      chrome_version:parseInt(chromeDetails?.[2], 10)?.toString()

    },
    "webengagenew"
  );
  return (
    //  process.env.NODE_ENV === "production" &&
    window.webengage.track(ACTION, {
      ...properties,
      cp_user_id : Number(userData.userId),
      tutor_id : Number(userData.tutorId),
      is_user_id_even:getEvenUserId(userData.userId),
      org_name : String(userData.orgName),
      org_id: Number(userData.orgId),
      org_code: String(userData.orgCode),
      user_type: Number(userData.userType),
      premium_status: Boolean(userData.premiumStatus),
      user_created_at: new Date(userData.userCreatedAtDate),
      signed_up_count: Number(userData.signedUpCount),
      email : String(userData.userEmail),
      mobile : Number(userData.userMobileNumber),
      user_name : String(userData.userName),
      screen_name : String(properties.screen_name),
      last_action : last_action || null,
      cp_sdk_source : "webview",
      chrome_version:parseInt(chromeDetails?.[2], 10)?.toString()
    })
  );
}


export function getEvenUserId(id){
  return Number(id)%2==0 ? true :false
}