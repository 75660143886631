import React, { Suspense, useEffect, useState } from "react";
import "./App.css";
import { Switch, Route, BrowserRouter, Redirect, useHistory } from "react-router-dom";
import GoalsModal from "./routes/GoalsModal/GoalsModal";
import HelpModal from "./routes/HelpModal/HelpModal";
import CongratsModal from "./routes/CongratsModal/CongratsModal";
import { getJourneyData, milestoneAchievedCallback, milestoneChatBroadCast, getAwardData } from "./api";
import domtoimage from 'dom-to-image';
import Shimmer from "./UI/Shimmer/Shimmer";
import { ToastContainer } from "react-toastify";
import URL from "./appConfig";
import "react-toastify/dist/ReactToastify.css";
import { youTubeGetID, handleDeeplink, appendToken, handleDeeplinkExecutedV2, getQuery } from "./utils"
import { newCleverTap, initUserProfileCleverTap } from "./cleverTap";
import ShareImage from "./UI/ShareImage/ShareImage";
import creativeUseIcon from "./assets/Story/creativeUse.svg";
import educatorAjtakIcon from "./assets/Story/educatorAjtak.svg";
import teacherGrowthIcon from "./assets/Story/teacherGrowth.svg";
import teacherTipIcon from "./assets/Story/teacherTip.svg";
import i18n from './i18n'

import {getEvenUserId} from "./webEngage"

const VideoModal = React.lazy(() => import("./routes/VideoModal/VideoModal"));
const MainPage = React.lazy(() => import("./routes/MainPage"));
const StoryPage = React.lazy(() => import("./routes/MainPage/Story/Story.jsx"));
const StoryMainPage = React.lazy(() => import("./routes/MainPage/StoryMainPage/StoryMainPage"));
const AllJourneys = React.lazy(() => import("./routes/AllJourneys"));
const ViewJourneys = React.lazy(() => import("./routes/ViewJourney"))
const Lesson2 = React.lazy(() => import("./routes/Lessons/LessonL2/LessonL2"))
const Lesson3 = React.lazy(() => import("./routes/Lessons/LessonL3/LessonL3"))
const YourJourneyCard = React.lazy(() => import("./routes/MainPage/Setup/YourJourneyCard/YourJourneyCard"))
const LessonsCard = React.lazy(()=>import('./routes/MainPage/LessonsCard/LessonsCard'))
const storyIcon = [
  creativeUseIcon,
  teacherTipIcon,
  teacherGrowthIcon,
  educatorAjtakIcon,
];

function App(props) {
  let [yourJourneyData, setYourJourneyData] = useState('')
  let [videoScroll, setVideoScroll] = useState(0)
  let [videoModal, setVideoModal] = useState(null)
  let [congratsData, setCongratsData] = useState('')
  let [goalsModal, setGoalsModal] = useState(false)
  let [helpModal, setHelpModal] = useState(false);
  let [congratsModal, setCongratsModal] = useState(false);
  let [goalsModalData, setGoalsModalData] = useState('')
  let [loading, setLoading] = useState(false)
  let [isShared, setIsShared] = useState(false)
  let [storyCompletion, setStoryCompletion] = useState([false, false, false])
  let [storyIndex, setStoryIndex] = useState([0, 0, 0])
  let [storyArr, setStoryArr] = useState([])
  let [selectedStory, setSelectedStory] = useState(0)
  const history = useHistory()
  const mobileCallBack = {
    CREATE_POSTER: "j3createPoster",
    NOTIFICATION_SENT: "j2shareNotification",
    COUPON_CREATED: "j2sellYourCourse",
  }

  const setInitialStoryData = (story) => {
    // const story = this.props?.yourJourneyData?.storiesSection?.stories || [];
    let newStoryCompletion = [];
    let newStoryIndexArr = [];
    let newStoryArr = [];
    for (let i = 0; i < story.length - 1; i += 1) {
      newStoryArr[i] = story[i];
      newStoryCompletion[i] = story[i].status;
      newStoryIndexArr[i] = 0
      for (let j = 0; j < story[i].content.length; j += 1) {
        if (!story[i].content[j].status) {
          newStoryIndexArr[i] = j
          break;
        }
      }
    }
    newStoryArr[3] = story[3]
    setStoryCompletion(newStoryCompletion)
    setStoryIndex(newStoryIndexArr)
    setStoryArr(newStoryArr)
    if(getQuery()['selectedStory']) {
      setSelectedStory(getQuery()['selectedStory'])
    }
  };

  const setCurrentStoryIndex = (index, isCompleted) => {
    let newStoryIndex = storyIndex
    let newStoryCompletion = storyCompletion
    newStoryIndex[selectedStory] = index
    setStoryIndex(newStoryIndex)
    if (isCompleted) {
      newStoryCompletion[selectedStory] = true
      setStoryCompletion(storyCompletion)
    }
  };

  const setStoryScreenView = (selectedStory, screenpreviewIndex = null, callback) => {

    if (storyArr[selectedStory]?.deeplink?.screen) {
      handleDeeplinkExecutedV2(JSON.stringify({
        "screen": `${storyArr[selectedStory].deeplink.screen}`,
        "paramOne": `${storyArr[selectedStory].deeplink.paramOne}`,
        "paramTwo": `${storyArr[selectedStory].deeplink.paramTwo}`,
        "paramThree": `${storyArr[selectedStory].deeplink.paramThree}`,
      }),window.location.pathname == "/storyhomeCard" ? "HOME":'')
      return;
    }
    if (screenpreviewIndex != null) {
      let tempStoryIndexArr = storyIndex
      tempStoryIndexArr[2] = screenpreviewIndex
      setStoryIndex(tempStoryIndexArr)
      // this.setState({storyIndexArr:[...tempStoryIndexArr]})
    }
    if (
      !storyArr[selectedStory]?.content.length ||
      !storyArr[selectedStory].content
    ) {
      return;
    }
    setSelectedStory(selectedStory)
    // alert(selectedStory)
    // history.push(appendToken("/story"));
    if (window.location.pathname == "/storyhomeCard") {
      handleDeeplinkExecutedV2(
        JSON.stringify({
          screen: "UTIL_WEBVIEW",
          paramOne: `${URL.prod_WebBaseUrl}/story?token={hash}&selectedStory=${selectedStory}`,
        }),
        "HOME"
      );
    } else {
      history.push(appendToken("/story"));
    }

    // this.setState({
    //   currentView: selectedScreen,
    // });
  };



  const setVideoModalData = (modalData) => {
    const youtubeId = youTubeGetID(modalData)
    setVideoModal(youtubeId)
  }

  useEffect(() => {
    window.onMobileUpdate = dataobj => {
      if (dataobj) {
        if (dataobj == "CHAT_BROADCAST_SENT") {
          milestoneChatBroadCast()
        } else {
        milestoneAchievedCallback(mobileCallBack[dataobj])
        }
      }
    };
  })

  useEffect(() => {
    // alert('1')
    // newCleverTap("See_other_journeys",{})
    setLoading(true)
    const defaultLanguage = getQuery()['defaultLanguage']
    if(defaultLanguage) {
      i18n.changeLanguage(defaultLanguage)
    }
    getJourneyData().then((data) => {
      window.webengage.user.login(data?.userInformation?.userId);
      window.webengage.user.setAttribute("cp_user_id", data?.userInformation?.userId || "");
      window.webengage.user.setAttribute("is_user_id_even",getEvenUserId(data?.userInformation?.userId))
      data.userInformation && localStorage.setItem("userInfo", JSON.stringify(data.userInformation))
      data.userInformation && initUserProfileCleverTap(data.userInformation)
      setInitialStoryData(data.storiesSection.stories)
      setYourJourneyData(data)
      setCongratsData(data && data.successModal)
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    });
   

  }, [])

  useEffect(() => {
    if (congratsData && Object.keys(congratsData).length && window.location.pathname=='/') {
      setCongratsModal(true)
    }
  }, [congratsData])
  useEffect(() => {
    if (videoModal) {
      setVideoScroll(window.scrollY)
      document.body.style.overflowY = "hidden";
    }
    else {
      document.body.style.overflowY = "scroll";
      window.scroll(0, videoScroll)
    }
  }, [videoModal])

  useEffect(() => {
    if (helpModal) {
      setVideoScroll(window.scrollY)
      document.body.style.overflowY = "hidden";
    }
    else {
      document.body.style.overflowY = "scroll";
      window.scroll(0, videoScroll)
    }
  }, [helpModal])

  useEffect(() => {
    if (congratsModal) {
      setVideoScroll(window.scrollY)
      document.body.style.overflowY = "hidden";
    }
    else {
      document.body.style.overflowY = "scroll";
      window.scroll(0, videoScroll)
    }
  }, [congratsModal])

  useEffect(() => {
    if (goalsModal) {
      setVideoScroll(window.scrollY)
      document.body.style.overflowY = "hidden";
    }
    else {
      document.body.style.overflowY = "scroll";
      window.scroll(0, videoScroll)
    }
  }, [goalsModal])


  const handleShareImgBase = () => {
    document.getElementById("share-image").style.display = "flex";
    domtoimage.toBlob(document.getElementById("share-image"), { quality: 1 })
    .then(function (dataUrl) {
      var reader = new FileReader();
      reader.readAsDataURL(dataUrl);
      reader.onloadend = function () {
        var base64data = reader.result;
        base64data = btoa(base64data)
        handleDeeplink("UTIL_SHARE_IMAGE_BASE64",`${base64data}`)
      }
    }).then(()=>{document.getElementById("share-image").style.display = "none";})
}    

  return (loading ? <Shimmer/>:
    <div className="App">
      <Suspense fallback={<Shimmer />}>
        <Switch>
            <Route exact path="/" component={(props) => <MainPage
              {...props}
              storyIcon={storyIcon}
              loading={loading}
              setVideoModalData={setVideoModalData}
              setGoalsModal={setGoalsModal}
              setHelpModal={setHelpModal}
              yourJourneyData={yourJourneyData}
              setGoalsModalData={setGoalsModalData}
              setYourJourneyData={setYourJourneyData}
              setLoading={setLoading}
              setCongratsData={setCongratsData}
              setScreenView={setStoryScreenView}
              
            />}
            />

            <Route exact path="/story" component={() => <StoryPage
              headerText={storyArr[selectedStory]?.title}
              headerIcon={storyIcon[selectedStory]}
              data={storyArr[selectedStory]?.content}
              setScreenView={setStoryScreenView}
              setStoryIndex={setCurrentStoryIndex}
              storyIndex={storyIndex[selectedStory]}
              selectedStory={selectedStory}
            />} />

            <Route exact path="/allJourneys" component={() => <AllJourneys
              setCongratsModal={setCongratsModal}
              // allJourneyData={allJourneyData}
              setYourJourneyData={setYourJourneyData}
              setCongratsData={setCongratsData}
              setIsShared={setIsShared}
              setLoading={setLoading}

            />}
            />
            {/* <Route path="/share" component={ShareImage} /> */}

            <Route path="/l2/:lesson2Id" component={Lesson2} />
            <Route path="/l3/:lesson2Id/:l2Index/:lesson3Id" component={(props) => <Lesson3 {...props} setVideoModalData={setVideoModalData} />} />
            <Route exact path="/viewJourneys" component={(props) => <ViewJourneys {...props} setLoading={setLoading} setVideoModalData={setVideoModalData}
            setHelpModal={setHelpModal}
            data={yourJourneyData.journeys}
            goToNextJourney={yourJourneyData.goToNextJourney}
            setYourJourneyData={setYourJourneyData}
              />} />

            <Route exact path="/storyhomeCard" component={() => <StoryMainPage
                                storyIcon={storyIcon}
                                data={yourJourneyData.storiesSection}
                                storyCompletion={storyCompletion}
                                setScreenView={setStoryScreenView}
                                />} />
                    
              <Route exact path="/homeCard" component={() => <YourJourneyCard
                                    setVideoModalData={setVideoModalData}
                                    yourJourneyData={yourJourneyData}
                                    setHelpModal={setHelpModal}
                                    setYourJourneyData={setYourJourneyData}
                                    />} />
              <Route exact path='/lessonsHomeCard' component={()=><LessonsCard yourJourneyData={yourJourneyData}/>}/>
          
            <Route render={() => <Redirect to="/" />} />
          </Switch>
        {!!videoModal ? <VideoModal videoModal={videoModal} setVideoModal={setVideoModal} /> : <></>}
        {congratsModal ? <CongratsModal handleShareImgBase={handleShareImgBase} yourJourneyData={yourJourneyData} setYourJourneyData={setYourJourneyData} isShared={isShared} setIsShared={setIsShared} congratsModal={congratsModal} setCongratsModal={setCongratsModal} congratsData={congratsData} /> : ""}
        {helpModal ? <HelpModal yourJourneyData={yourJourneyData} setHelpModal={setHelpModal} helpModal={helpModal}/>:""}
        {goalsModal ? <GoalsModal goalsModalData={goalsModalData} yourJourneyData={yourJourneyData} goalsModal={goalsModal} setGoalsModal={setGoalsModal}/>:""}

          <ShareImage yourJourneyData={yourJourneyData}/>
        <ToastContainer
          style={{ fontSize: "16px" }}
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Suspense>
     
    </div>
  );
}

export default App;
