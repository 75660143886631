export const handleDeeplink=(screen,paramOne,paramTwo,paramThree)=>{
    let param =  screen && paramOne && paramTwo && paramThree 
                ? `${screen},${paramOne},${paramTwo},${paramThree}`
                :screen && paramOne && paramTwo
                ? `${screen},${paramOne},${paramTwo}`
                : screen && paramOne
                ?`${screen},${paramOne}` 
                : screen 
                ? `${screen}` 
                :paramOne;

    console.log(param)
    if (param == "UTIL_WEBVIEW_CLOSE") {
      window?.mobile?.setTestState("QUIT");
      window?.webkit?.messageHandlers?.stateEvents?.postMessage('QUIT');
      return
    }
    if (window && window.mobile && window.mobile.onDeeplinkExecuted) {
        window.mobile.onDeeplinkExecuted(param);
      }
      if (
        window &&
        window.webkit &&
        window.webkit.messageHandlers &&
        window.webkit.messageHandlers.onDeeplinkExecuted
        ) {
          window.webkit.messageHandlers.onDeeplinkExecuted.postMessage(param);
        }
}


export const handleDeeplinkExecutedV2 = (jsonVal, source) => {
  if (source == "HOME") {
    window?.webkit?.messageHandlers?.onDeeplinkExecutedV2?.postMessage(jsonVal);
    window?.mobile?.exceuteDeeplink(jsonVal);
  } else {
    window?.webkit?.messageHandlers?.onDeeplinkExecutedV2?.postMessage(jsonVal);
    window?.mobile?.onDeeplinkExecutedV2(jsonVal);
  }
};


export function appendToken(url) {
  return url + `?token=${getQuery()[`token`]}`
}

export function youTubeGetID(url) {
  var ID = '';
  url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_\-]/i);
    ID = ID[0];
  }
  else {
    ID = url;
  }
  return ID;
}

export function getQuery() {
	let b = window.location.search.slice(1).split('&').map((qStr) => qStr.split('=')).reduce((acc, inc) => {
		acc[inc[0]] = inc[1];
		return acc;
	}, {});

	return b;
}

export const defaultToken = "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6NDUzNzMzLCJvcmdJZCI6MSwidHlwZSI6MywibW9iaWxlIjoiOTE4MDc2NzY3MzE1IiwibmFtZSI6IkNMUCBNQVNURVIgMTk5MyIsImVtYWlsIjoiZW5naW5lZXJpbmdAY2xhc3NwbHVzLmNvIiwiaWF0IjoxNjI3MDMwNzk0LCJleHAiOjE2Mjg3NTg3OTR9.xB4ijFdH3Ro82itgYSJYrccS-dZGnARl6cf1hRYW_9qeU9PLW35C9OOxWxhCrlFB"
//'eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6NDUzNzMzLCJvcmdJZCI6MSwibmFtZSI6IkNMUCBNQVNURVIgMTk5MyIsImVtYWlsIjoiZW5naW5lZXJpbmdAY2xhc3NwbHVzLmNvIiwibW9iaWxlIjoiOTE4MDc2NzY3MzE1IiwidHlwZSI6MywiaWF0IjoxNjI1Njg4NDU5LCJleHAiOjE2Mjc0MTY0NTl9.1NWM4w7EocCb4KOlrZpzgsX532i9zFC_3icsReK4sP7qls4MkWNeqpPGFljajfdP'
// export const defaultToken='eyJhbGIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6NDUzNzMzLCJvcmdJZCI6MSwibmFtZSI6IkNMUCBNQVNURVIgMTk5MyIsImVtYWlsIjoiZW5naW5lZXJpbmdAY2xhc3NwbHVzLmNvIiwibW9iaWxlIjoiOTE4MDc2NzY3MzE1IiwidHlwZSI6MywiaWF0IjoxNjI1Njg4NDU5LCJleHAiOjE2Mjc0MTY0NTl9.1NWM4w7EocCb4KOlrZpzgsX532i9zFC_3icsReK4sP7qls4MkWNeqpPGFljajfdP'